#cartaz {
    padding: 2%;
    background-image: url("../Photos/Capa.jpg");
    background-size: cover;
    background-position: center center;
    height: 50vh;
}

#logo{
    width: 10%;
    height: auto;
}

@media (max-width: 700px) {
    #logo{
        width: 40%;
        height: auto;
    }
    
    #cartaz {
        height: 30vh;
    }
}