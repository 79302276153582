.App {
  text-align: center;
  height: 100%;
  width: auto;
  min-height: 100%;
}

.css-shadow {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 2px solid #61dafb;
  filter: drop-shadow(0 0 8px #000000);
}

iframe {
  border-radius: 2%;
  height: 20%;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (min-width: 1024px) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }

  h1 {
    font-size: 60px;
  }

  iframe {
    border-radius: 2%;
    height: 100%;
    width: 100%;
  }

  .grid {
    min-height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }

  .grid>div {
    flex-basis: calc(30% - 40px);
  }
}

.grid {
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.App-header {
  background-color: #363738;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#alert {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
}

h1 {
  font-size: 40px;
  padding-top: 20px;
}

h2 {
  text-align: left;
  color: #c25431;
  font-weight: bolder;
}

h3 {
  text-align: left;
  color: #c25431;
  font-weight: bolder;
}

h5 {
  text-align: center;
  color: aliceblue;
  font-weight: 100;
  font-style: italic;
}


p {
  text-align: justify;
}

a{
  color: white;
}