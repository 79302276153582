$primary: #2b2d30;
$secondary: white;

$body-bg: white;
$body-color: #2b2d30;

$font-family-sans-serif:      system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$h1-color: $body-bg;
$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
);

@import "../node_modules/bootstrap/scss/bootstrap";